import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { breakpoint } from '../../shared/theme'
import SinglePostInner from '../../elements/singlePostInner'
import Placeholder from '../../images/single-placeholder.png'
import SectionTransition from '../../elements/activeSectionDefault'

const Row = styled(SectionTransition)`
	padding-bottom: 60px;

	@media ${breakpoint('tablet')} {
		padding-bottom: 136px;
		grid-column: span 10;
	}

	@media ${breakpoint('desktop')} {
		padding-bottom: 160px;
		grid-column: span 9;
	}
`

const Wrapper = styled.div`
	grid-column: -1 / 1;

	@media ${breakpoint('tablet')} {
		grid-column: span 10;
	}

	@media ${breakpoint('desktop')} {
		grid-column: span 9;
	}
`

const ImageWrap = styled.div`
	position: relative;

	&::before {
		content: '';
		display: block;
		width: 100%;
		height: 0;
		padding-top: 100%;

		@media ${breakpoint('tablet')} {
			padding-top: ${() => {
				return `${(590 / 1200) * 100}%`
			}};
		}
	}
`

const Image = styled.img``

const SingleImage = ({ isActive, image }) => {
	if (!image || image.length <= 0) {
		return null
	}
	return (
		<Row isActive={isActive}>
			<SinglePostInner>
				<Wrapper>
					<ImageWrap>
						<Image
							src={image.mediaItemUrl}
							className="bg-cover"
							alt={image.altText}
						/>
					</ImageWrap>
				</Wrapper>
			</SinglePostInner>
		</Row>
	)
}

SingleImage.propTypes = {
	isActive: PropTypes.bool,
	image: PropTypes.object,
}

SingleImage.defaultProps = {
	isActive: true,
	image: {},
}

export default SingleImage
